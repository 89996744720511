jQuery(document).ready(function($) {

    // Append search template
    var searchTemplate = $('.search-template').clone();
        searchTemplate.removeAttr('hidden');
        searchTemplate.attr('id', 'search');
    $('#headerMenu .nav-search').parent().append(searchTemplate);

    $('#headerMenu .nav-item-parent').on('click', function(event) {
        if($(window).width() < 977) {
            event.preventDefault();
            if($(this).hasClass('open')) {
                return window.location = $(this).find('> a').attr('href');
            }
            $('#headerMenu .nav-item-parent').removeClass('open');
            $(this).addClass('open');
        }
    });

    $('#headerMenu .nav-search').on('click', toggleSearch);

    $('#search .search-close').on('click', toggleSearch);

    $('#search').on('submit', function(event) {
        event.preventDefault();

        resetSearch();
        showLoading();

        $.ajax({
            url: '/wp-json/w2we/search',
            method: 'GET',
            data: { 
                query : $('#search input').val()
            },
            success: function(response) {
                hideLoading();

                if(!response.count) {
                    $('#search').append($('#templates .search-no-results-template').clone().removeAttr('hidden'));
                    return;
                }

                $('#search').append($('#templates .search-result-template').clone().removeAttr('hidden'));

                var searchResultEl =  $('#search .search-result-template');  
                var searchResultPostTemplate =  $('#templates .search-result-post-template');

                searchResultEl.find('[data-result-count]').replaceWith('1 to ' + response.count);

                for(var i in response.posts) {
                    var post = response.posts[i];
                    var postTemplate = searchResultPostTemplate.clone().removeAttr('hidden');

                    postTemplate.find('[data-title]').attr('href', post.permalink).text(post.title);
                    postTemplate.find('[data-url]').text(post.permalink);
                    postTemplate.find('[data-excerpt]').text(post.excerpt);
                    postTemplate.appendTo('#searchPostsResults');
                }
            }
        });
    });

    function resetSearch() {
        $('#search .search-no-results-template').remove();
        $('#search .search-result-template').remove();
        $('#search .search-loading-template').remove();
    }

    function toggleSearch() {
        resetSearch();
        $('#search input').val('');
        $('#search').toggle();
        $('#headerNav').toggleClass('search-open');
    }

    function showLoading() {
        $('#search').append($('#templates .search-loading-template').clone().removeAttr('hidden'));
    }

    function hideLoading() {
        $('#search .search-loading-template').remove();
    }


    // Go to page and open accordion
    if($('body').hasClass('page-parent')) {
        var urlParams = new URLSearchParams(window.location.search);
        scrollToSection(urlParams.get('section'));
    }

    function scrollToSection(section) {
        if(!section) return;
        var accordionItem = $('[data-target="#' + section + '"]');
        accordionItem.click();
        
        $("html, body").animate({ 
            scrollTop: accordionItem.offset().top
        }, 1000);
    }

    // Hero
    $('#hero .slider').slick({
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 1,
        draggable: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        dots: true,
        // autoplay: true
    });

    
    $('.language-banner').slick({
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });
});