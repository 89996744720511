jQuery(document).ready(function($) {
    "use strict"
    jQuery('.neighbourhood-map-municipality-link').on('click', function(e) {
        jQuery('.neighbourhood-map-details').hide();
        jQuery('#map-details-' + jQuery(this).attr('id')).show();
    })

    $('.map-essex-county-map').on('click', function(e) {
        let target = e.target;
        let target_id = target.id;
        console.log(target.id);

        switch(target_id) {
            case 'lasalle':
                window.open('http://www.lasalle.ca/', '_blank');
                break;
            case  'windsor':
                window.open('https://www.citywindsor.ca/', '_blank');
                break;
            case  'tecumseh':
                window.open('https://www.tecumseh.ca/', '_blank');
                break;
            case  'lakeshore':
                window.open('https://www.lakeshore.ca/', '_blank');
                break;
            case  'amherstburg':
                window.open('https://www.amherstburg.ca/', '_blank');
                break;
            case  'essex':
                window.open('https://www.essex.ca/', '_blank');
                break;
            case  'kingsville':
                window.open('https://www.kingsville.ca/', '_blank');
                break;
            case  'leamington':
                window.open('https://www.leamington.ca/', '_blank');
                break;
            case  'pelee-island':
                window.open('https://www.pelee.org/', '_blank');
                break;
            default:
                break;
        }
    })
});